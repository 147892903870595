/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/Logo.jpg";
import GooglePlay from "../../assets/img/google-play.256x256.png";
import Instagram from "../../assets/img/instagram.png";
// components

export default function Navbar(props) {
  
  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-1 navbar-expand-lg bg-white shadow">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="px-2">
            <img src={Logo} alt="" height="auto" width="50px"/>
          </div>
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start left-nav">
            <Link
              to="/"
              className="text-blueGray-700 text-xl font-bold leading-relaxed inline-block mr-4 whitespace-nowrap uppercase"
            >
              Nest Aura
            </Link>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none block"
            }
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto right-nav">
              <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-3xl uppercase font-bold"
                  href="../../assets/builds/nestaura-android.apk"
                  download
                >
                  <img src={GooglePlay} alt="" height="auto" width="40px"/>
                </a>
              </li>

              <li className="flex items-center">
                <a
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-3xl uppercase font-bold"
                    href="https://www.instagram.com/nestaura.ai"
                    target="_blank"
                  >
                    <img src={Instagram} alt="" height="auto" width="40px"/>
                  </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
